import { faCheckCircle, faCrown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';

const PlanCard = ({ plan, selectPlan, selectedPlan }) => {
    return (
        <motion.div
            whileHover={{ scale: 1.05 }}
            className={`relative border p-6 rounded-lg shadow-lg transition-all duration-300 cursor-pointer 
                        ${selectedPlan?.id === plan.id ? 'border-green-500' :
                    plan.highlighted ? 'border-yellow-500' : 'border-gray-300'}
                        ${plan.highlighted ? 'hover:border-yellow-500' : 'hover:border-blue-500'}
            `}
            onClick={() => typeof selectPlan === 'function' ? selectPlan(plan) : null}
        >
            {plan.highlighted && (
                <div className="absolute -top-5 -right-5">
                    <div className="bg-yellow-500 p-2 rounded-full shadow-lg">
                        <div className='text-white text-md'>
                            <FontAwesomeIcon icon={faCrown} className="mr-2" />
                            Destacado
                        </div>
                    </div>
                </div>
            )}
            <div className="flex justify-between items-center">
                <h4 className={`text-lg font-semibold mb-2 pe-4 ${plan.highlighted ? 'text-yellow-500' : 'text-[#3E11B5]'}`}>{plan.title}</h4>
                {selectedPlan?.id === plan.id && (
                    <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 text-xl" />
                )}
            </div>
            <p className="text-gray-700 mb-1">{plan.price}</p>
            <p className={`text-gray-500 mb-3 ${plan.days_available === 0 ? 'text-green-500' : ''}`}>
                {plan.days_available === 0 ? 'Para siempre' : plan.duration}
            </p>
            <ul className="list-disc list-inside mb-3 text-gray-600">
                <li>{plan.label1}</li>
                {plan.label2 && <li>{plan.label2}</li>}
                {plan.promotion_label && <li className='text-green-500'>{plan.promotion_label}</li>}
            </ul>
            <div className="flex flex-wrap gap-2 mb-3">
                {plan.needs_membership && (
                    <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800">
                        Membresía requerida
                    </span>
                )}
                {plan.price === '$0.00 c/u' && (
                    <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        Gratis
                    </span>
                )}
                {plan.highlighted && (
                    <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                        <FontAwesomeIcon icon={faCrown} className='mr-1' />
                        Destacado
                    </span>
                )}
            </div>
        </motion.div>
    );
};

export default PlanCard;
