import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const ProtectedRoute = ({ user, redirectTo = "/auth/login", children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!user || Object.keys(user).length === 0) {
            navigate(redirectTo, { replace: true, state: { from: location } });
        }
    }, [user, redirectTo, location, navigate]);

    if (!user || Object.keys(user).length === 0) {
        return null;
    }

    return children;
};

export default ProtectedRoute;
