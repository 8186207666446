import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Pagination = ({ currentPage, totalCount, pageSize, onPageChange }) => {
  const totalPages = Math.ceil(totalCount / pageSize);

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <nav aria-label="Page navigation example" className="flex justify-center mt-4">
      <ul className="flex list-none p-0">
        <li className={`page-item ${currentPage === 1 ? 'pointer-events-none' : ''}`}>
          <a
            className="page-link flex items-center justify-center w-10 h-10 mx-1 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors duration-200"
            href="javascript:;"
            aria-label="Previous"
            onClick={handlePrevious}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            <span className="sr-only">Previous</span>
          </a>
        </li>
        {[...Array(totalPages)].map((_, index) => (
          <li key={`page-${index}`} className="page-item">
            <a
              className={`page-link flex items-center justify-center w-10 h-10 mx-1 rounded-full ${currentPage === index + 1 ? 'bg-purple-800 text-white' : 'bg-gray-200 hover:bg-gray-300'} transition-colors duration-200`}
              href="javascript:;"
              onClick={() => onPageChange(index + 1)}
            >
              {index + 1}
            </a>
          </li>
        ))}
        <li className={`page-item ${currentPage === totalPages ? 'pointer-events-none' : ''}`}>
          <a
            className="page-link flex items-center justify-center w-10 h-10 mx-1 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors duration-200"
            href="javascript:;"
            aria-label="Next"
            onClick={handleNext}
          >
            <FontAwesomeIcon icon={faAngleRight} />
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
