import { useEffect, useState } from 'react';
import { getTenantInfo } from '../../services/chats.service';
import Spinner from '../Loader/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons';

const ConversationTenantInfo = ({ chatId, onClose }) => {
  const [tenantInfo, setTenantInfo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTenantInfo = async () => {
      try {
        setLoading(true);
        const tenantInfo = await getTenantInfo(chatId);
        setTenantInfo((current) => tenantInfo);
      } catch (error) {
        console.error('Error fetching tenant info:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchTenantInfo();
  }, [chatId]);

  const openWhatsapp = () => {
    window.open(`https://wa.me/${tenantInfo.phone}`, '_blank');
  };

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white rounded-lg shadow-lg w-11/12 sm:w-96 p-4 relative max-h-[40rem] overflow-y-auto flex items-center justify-center h-screen">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={typeof onClose === 'function' ? onClose : null}
    >
      <div
        className="bg-white rounded-lg shadow-lg w-10/12 sm:w-96 p-4 relative max-h-[40rem] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-2 left-0  text-gray-500 hover:text-gray-700"
        >
          <FontAwesomeIcon icon={faX} className="mr-4 w-8" />
        </button>
        <h1 className="text-2xl font-bold mb-4 text-center">
          Información del inquilino
        </h1>
        <img
          src={tenantInfo.profile_picture || 'https://via.placeholder.com/150'}
          alt="Profile"
          className="w-24 h-24 rounded-full object-cover mb-4 self-center bg-gray-200 mx-auto"
        />
        <p className="text-lg mb-2">Nombre: {tenantInfo.name}</p>
        <p className="text-lg mb-2">Email: {tenantInfo.email}</p>
        <p className="text-lg mb-2">Teléfono: {tenantInfo.phone}</p>
        <button
          onClick={openWhatsapp}
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
        >
          <FontAwesomeIcon icon={faWhatsapp} className="w-4 h-4 mr-2" />
          Contactar por whatsapp
        </button>
      </div>
    </div>
  );
};

export default ConversationTenantInfo;
