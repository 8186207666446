import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { motion } from 'framer-motion';
import LocationSearchInput from '../../../components/maps/filters/input-location';
import MapsWrapper from '../../../components/maps/maps-wrapper';
import useGoogleMaps from '../../../hooks/useGoogleMaps';

const AddressSearch = ({ step, setStep, handlePreviousStep, updateFormData, contador }) => {
    const loaded = useGoogleMaps();

    const [location, setLocation] = useState({
        lat: 20.6736,
        lng: -103.344,
        address: '',
        exterior: '',
        interior: '',
        suburb: '',
        city: '',
        state: '',
        formatted_address: '',
        select: false,
        suburb: ''
    });

    const [showAddress, setShowAddress] = useState(false);
    const [error, setError] = useState(null);

    const handleLocationChange = (newLocation) => {
        setLocation((prev) => ({
            ...prev,
            ...newLocation,
        }));
        updateFormData('location', {
            ...location,
            ...newLocation,
        });
    };

    const handleCheckboxChange = () => {
        setShowAddress(!showAddress);
        updateFormData('show_address', !showAddress);
    };

    const onConfirmAddress = () => {
        setStep(step + 1);
    };

    const handleInputChange = (field, value) => {
        setLocation((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleManualAddressChange = async () => {
        try {
            const fullAddress = `${location.address} ${location.exterior}, ${location.suburb}, ${location.city}, ${location.state}`;
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=${process.env.REACT_APP_GOOGLE_KEY}`
            );
            const data = await response.json();

            if (data.results.length === 0) {
                setError('Ubicación no encontrada');
                return;
            }

            const result = data.results[0];
            const latLng = result.geometry.location;

            const updatedLocation = {
                lat: latLng.lat,
                lng: latLng.lng,
                address: location.address,
                exterior: location.exterior,
                interior: location.interior,
                suburb: location.suburb,
                city: location.city,
                state: location.state,
                formatted_address: fullAddress,
                select: true,
            };

            setError(null);
            setLocation(updatedLocation);
            updateFormData('location', updatedLocation);
        } catch (error) {
            console.error('Error during manual geocoding:', error);
            setError('Error al buscar la ubicación');
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center bg-white p-4 md:p-8 rounded-lg w-full"
        >
            <h2 className="text-[#3E11B5] font-semibold text-3xl mb-6">Ubica tu inmueble</h2>
            <h4 className="text-[#3E11B5] font-semibold text-center text-xl mb-6">
                Puedes ingresar la dirección <br /> o arrastrar el pin para ubicar el inmueble
            </h4>
            <div className="w-full mb-4">
                {loaded && (
                    <LocationSearchInput
                        setLocation={handleLocationChange}
                        location={location}
                        inputValue={location.formatted_address}
                    />
                )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full mb-6">
                <input
                    type="text"
                    placeholder="Dirección (obligatorio)"
                    className="border p-2 rounded w-full"
                    value={location.address}
                    onChange={(e) => handleInputChange('address', e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Número exterior (obligatorio)"
                    className="border p-2 rounded w-full"
                    value={location.exterior}
                    onChange={(e) => handleInputChange('exterior', e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Número interior (opcional)"
                    className="border p-2 rounded w-full"
                    value={location.interior}
                    onChange={(e) => handleInputChange('interior', e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Colonia (obligatorio)"
                    className="border p-2 rounded w-full"
                    value={location.suburb}
                    onChange={(e) => handleInputChange('suburb', e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Municipio o ciudad (obligatorio)"
                    className="border p-2 rounded w-full"
                    value={location.city}
                    onChange={(e) => handleInputChange('city', e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Estado (obligatorio)"
                    className="border p-2 rounded w-full"
                    value={location.state}
                    onChange={(e) => handleInputChange('state', e.target.value)}
                />

            </div>
            <button
                onClick={handleManualAddressChange}
                className="bg-blue-500 text-white px-4 py-2 rounded-md w-full md:col-span-2 mb-5"
            >
                Buscar Ubicación
            </button>
            <div className="relative w-full">
                {loaded && (
                    <MapsWrapper
                        zoom={16}
                        handleLocationChange={handleLocationChange}
                        location={location}
                    />
                )}
            </div>
            <div className="flex items-center space-x-2 mt-3">
                <input
                    type="checkbox"
                    id="show_address"
                    className="form-checkbox h-5 w-5 text-blue-600"
                    checked={showAddress}
                    onChange={handleCheckboxChange}
                />
                <label htmlFor="show_address" className="text-gray-700">
                    Mostrar dirección en el anuncio
                </label>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-[44px]">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="flex items-center text-white bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700 transition duration-300 px-6 py-3 rounded-full"
                    onClick={handlePreviousStep}
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Regresar
                </motion.button>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`flex items-center text-white transition duration-300 px-6 py-3 rounded-full ${location.select
                        ? 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700'
                        : 'bg-gray-300 cursor-not-allowed'
                        }`}
                    onClick={onConfirmAddress}
                    disabled={!location.select}
                >
                    Continuar
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </motion.button>
            </div>
        </motion.div>
    );
};

export default AddressSearch;
