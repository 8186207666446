import axios from './index';
//Enviar solcitud de chat
export const sentRequestChat = async (propertyId, token = null) => {
  const response = await axios.post(
    '/app/chats/requests',
    {
      propertyId: propertyId, // Include propertyId in the body
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  console.log(response);
  return response;
};
// Obtiene los chats
export const getChats = async (query, token = null) => {
  try {
    // URL corregida según el comentario
    const url = `/app/chats/conversations/${query}`;
    //console.log("Received request");
    //console.log("Query:", query);
    //console.log("Token:", token);
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    //console.log(response);
    //console.log("response");

    return response.data;
  } catch (error) {
    console.error('Error fetching chats received:', error);
    throw error;
  }
};

//crea un servicio para obtener un chat individual con ruta app/chats/conversations/received/:conversationId/messages?limit=10&skip=0
export const getChatById = async (conversationId, token = null) => {
  try {
    const url = `/app/chats/conversations/received/${conversationId}/messages?limit=10&skip=0`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching chat received:', error);
    throw error;
  }
};

//Obtiene la información del inquilino
export const getTenantInfo = async (conversationId) => {
  const url = `/app/chats/conversations/received/${conversationId}/contact`;
  const response = await axios.get(url);

  if (response.data.tenantContact) {
    return response.data.tenantContact;
  } else {
    return null;
  }
};

//Obtiene mensajes de un chat
export const ChatMessages = async (conversationId, token = null) => {
  try {
    const url = `/app/chats/conversations/sent/${conversationId}/messages`;

    //console.log("Conversation ID:", conversationId);

    //console.log("Token:", token);
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener el chat:'.conversationId, error);
    throw error;
  }
};

// export const requestChatReceivedOLD = async (query, token = null) => {
//   // {{hostname}}/app/chats/conversations/sent?skip=0&limit=10
//   //console.log("Received request");
//   //console.log(query);
//   //console.log(token);
//   //console.log("query");
//   //console.log("token");
//   return axios.get(`/app/chats/conversations/received?${query}`, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
// };

// Mensajes de chats recibidos
export const messagesChatReceived = async (query, token = null) => {
  try {
    const url = `/app/chats/conversations/received?${query}&status=accepted`;
    //console.log("Received messages request");
    //console.log("Query:", query);
    //console.log("Token:", token);
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching messages received:', error);
    throw error;
  }
}; // Mensajes de chats recibidos
// export const messagesChatReceived = async (query, token = null) => {
//   try {
//     const url = `/app/chats/conversations/received?${query}&status=accepted`;
//     //console.log("Received messages request");
//     //console.log("Query:", query);
//     //console.log("Token:", token);
//     const response = await axios.get(url, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching messages received:", error);
//     throw error;
//   }
// };
export const requestChatChangeStatus = async (
  conversationId,
  data,
  token = null
) => {
  try {
    const url = `/app/chats/conversations/received/${conversationId}`;
    //console.log("Change status request");
    //console.log("Conversation ID:", conversationId);
    //console.log("Data:", data);
    //console.log("Token:", token);
    const response = await axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return true;
  } catch (error) {
    console.error('Error changing chat status:', error);
    throw error;
  }
};

// Obtener mensajes de una conversación
export const messagesConversation = async (id, token = null) => {
  try {
    const url = `/app/chats/conversations/received/${id}/messages`;
    //console.log("Messages conversation request");
    //console.log("Conversation ID:", id);
    //console.log("Token:", token);
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching messages conversation:', error);
    throw error;
  }
};

// Marcar como leído todos los mensajes de una conversación
export const markAllMessagesAsRead = async (chatRoomId, token = null) => {
  try {
    const url = `/app/chats/conversations/received/${chatRoomId}/messages`;

    const response = await axios.put(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching messages conversation:', error);
    throw error;
  }
};

// Enviar mensaje en una conversación
export const sendMessagesConversation = async (id, data, token = null) => {
  try {
    const url = `/app/chats/conversations/sent/${id}/messages`;
    //console.log("Send message conversation request");
    //console.log("Conversation ID:", id);
    //console.log("Data:", data);
    //console.log("Token:", token);
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error sending message conversation:', error);
    throw error;
  }
};

// Función para enviar archivo a una conversación
export const sendFileConversation = async (
  token,
  id,
  messageFile,
  file_size,
  file_type
) => {
  try {
    const url = `/app/chats/conversations/received/${id}/messages`;
    //console.log("Preparing to send file...");

    const formData = new FormData();
    formData.append('messageFile', messageFile);
    formData.append('file_size', file_size);
    formData.append('file_type', file_type);

    //console.log("FormData constructed:", formData);
    //console.log("File:", messageFile);
    //console.log("File Size:", file_size);
    //console.log("File Type:", file_type);

    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    //console.log("File sent successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error in sendFileConversation:', error);
    throw error;
  }
};

// Obtener chats para investigación
export const getChatsForInvestigation = async (id, token = null) => {
  try {
    const url = `/app/chats/properties/${id}`;
    //console.log("Get chats for investigation request");
    //console.log("Property ID:", id);
    //console.log("Token:", token);
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching chats for investigation:', error);
    throw error;
  }
};

// Enviar a investigación
export const sendToInvestigation = async (iduser, propertyId, token = null) => {
  //console.log("User ID:", iduser);
  //console.log("Property ID:", propertyId);
  //console.log("Token:", token);

  try {
    const url = `/app/chats/properties/${propertyId}/investigate/${iduser}`;
    //console.log("Send to investigation request");

    const response = await axios.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error sending to investigation:', error);
    console.log(error.response);

    if (error.response) {
      // El servidor respondió con un código de estado fuera del rango 2xx
      console.error('Error en la respuesta de la API:', error.response.data);

      // Comprobar si el mensaje de error es específico
      if (
        error.response.data.message ===
        'You already requested investigation for this user and property'
      ) {
        return 'Ya solicitaste una investigación para este usuario y propiedad.';
        // Aquí puedes manejar este caso específico, por ejemplo, mostrar una alerta al usuario o registrar el error.
      }
    } else if (error.request) {
      // La solicitud se hizo pero no hubo respuesta
      console.error('No se recibió respuesta del servidor:', error.request);
    } else {
      // Algo sucedió al configurar la solicitud
      console.error(
        'Error en la configuración de la solicitud:',
        error.message
      );
    }

    throw error; // Vuelve a lanzar el error para manejarlo en otro lugar si es necesario
  }
};

// Enviar a investigación score
export const sendToInvestigationScore = async (
  iduser,
  propertyId,
  token = null
) => {
  console.log('User ID:', iduser);
  console.log('Property ID:', propertyId);
  console.log('Token:', token);

  try {
    const url = `/app/chats/properties/${propertyId}/investigate-identity/${iduser}`;
    //console.log("Send to investigation request");

    const response = await axios.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error sending to investigation:', error);
    console.log(error.response);

    if (error.response) {
      // El servidor respondió con un código de estado fuera del rango 2xx
      console.error('Error en la respuesta de la API:', error.response.data);

      // Comprobar si el mensaje de error es específico
      if (
        error.response.data.message ===
        'You already requested investigation for this user and property'
      ) {
        return 'Ya solicitaste una investigación para este usuario y propiedad.';
        // Aquí puedes manejar este caso específico, por ejemplo, mostrar una alerta al usuario o registrar el error.
      }
    } else if (error.request) {
      // La solicitud se hizo pero no hubo respuesta
      console.error('No se recibió respuesta del servidor:', error.request);
    } else {
      // Algo sucedió al configurar la solicitud
      console.error(
        'Error en la configuración de la solicitud:',
        error.message
      );
    }

    throw error; // Vuelve a lanzar el error para manejarlo en otro lugar si es necesario
  }
};

// Actualizar estado de renta
export const updateStatusRent = async (id, data, token = null) => {
  try {
    const url = `/app/chats/properties/${id}/rents`;
    //console.log("Update status rent request");
    //console.log("Property ID:", id);
    //console.log("Data:", data);
    //console.log("Token:", token);
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating status rent:', error);
    throw error;
  }
};

// Obtener usuarios de investigación
export const getInvestigationUsers = async (id, token = null) => {
  try {
    const url = `/app/chats/properties/${id}/investigations`;
    //console.log("Get investigation users request");
    //console.log("Property ID:", id);
    //console.log("Token:", token);
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching investigation users:', error);
    throw error;
  }
};
//Obtiene las tags
export const getTags = async (token = null) => {
  try {
    const url = `/app/chats/tags`;
    console.log('Received request');
    console.log('Token:', token);
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error fetching chats received:', error);
    throw error;
  }
};

//Reporta el chat
export const postReportChat = async (ChatID, reason, token = null) => {
  try {
    const data = {
      chatRoomId: ChatID,
      reason: reason,
    };

    const url = `app/chats/reports`;
    //console.log("ChatID:", ChatID);
    //console.log("Token:", token);
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //console.log(response);
    return response;
  } catch (error) {
    console.error('Error fetching chats received:', error);
    throw error;
  }
};

//Reporta el chat
export const postTag = async (idTag, ChatID, token = null) => {
  try {
    const data = {
      tagId: idTag,
    };
    //console.log("tag:", idTag);
    const url = `/app/chats/conversations/received/${ChatID}/tags`;
    //console.log("ChatID:", ChatID);
    //console.log("Token:", token);
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //console.log(response);
    return response;
  } catch (error) {
    console.error('Error fetching chats received:', error);
    throw error;
  }
};
