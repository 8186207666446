import React, { createContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
const AuthContext = createContext();

const AuthProvider = (props) => {
	const [user, setUser] = useState(null);

	useEffect(() => {
		checkLogin();
	}, [props.userData]);

	const checkLogin = () => {
		setUser(props.userData);
	}

	return (
		<AuthContext.Provider
			value={{
				user, // Esto ahora será el objeto `userData`
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

const mapStateToProps = state => ({
	userData: state.auth.userData,
	isLoggedIn: state.auth.isLoggedIn
});

export { AuthContext };
export default connect(mapStateToProps, null)(AuthProvider);
