import googleLogo from "../../assets/google.png";
import { useGoogleLogin } from '@react-oauth/google';
import axios from '../../services';
import { useNavigate } from 'react-router-dom';

export const GoogleAuth = () => {

    const navigate = useNavigate();

    const onSuccess = async (codeResponse) => {
        const finishProfile = '/profile?t='
        try {
            const response = await axios.post(
                '/core/auth/google', {
                    code: codeResponse.code,
                });

            if(response.status !== 200) {
                throw new Error('Error al autenticar con Google');
            }

            const provisionalToken = response.data.provisionalToken;
            if(provisionalToken) {
                window.location.href = finishProfile + provisionalToken;
                return;
            }

            const token = response.data.token;
            const user = response.data.user || {};
 
            localStorage.setItem("token-yo-rento", token);
            localStorage.setItem(
              "userData",
              JSON.stringify({
                token: token,
                role: "USER",
                id: user?.userId,
                user: user,
              })
            );

            const roles = Array.isArray(user.roles) ? user.roles : [];

            const redirectPath = (roles.includes('lessor') ? "/admin/dashboard?modal-first=1" : "/");
            window.location.href = redirectPath;

        }
        catch (error) {
            console.error(error);
        }

        
    }

    const onError = (error) => {
        console.log(error);
        
    }

    const onNonOAuthError = (error) => {
        console.log('onNonOAuthError', error);
    }

     
    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: onSuccess,
        onError: onError,
        onNonOAuthError: onNonOAuthError,
    });


    return (
        <>
            <button className="flex flex-row items-center justify-center gap-x-2 border border-gray-300 rounded-full px-4 py-2" onClick={googleLogin}>
                <img src={googleLogo} alt="Google" width={20} height={20} />
                Ingresar con Google
            </button> 
        </>
    )
}