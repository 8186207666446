import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import Navigation from "./navigation";
import AuthProvider from "./provider/auth";
import { store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";

import { GoogleOAuthProvider } from '@react-oauth/google';

// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [
//     Sentry.replayIntegration(),
//   ],
//   // Session Replay
//   replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });


// Encuentra el contenedor de la raíz
const container = document.getElementById("root");
// Crea una raíz
const root = createRoot(container);

// Renderiza tu aplicación en la raíz
root.render(
  <Provider store={store}>
    <AuthProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
        <BrowserRouter>
          <Navigation />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </AuthProvider>
  </Provider>
)
